import { css } from "@emotion/core"

export const callToActionStyle = theme => {
  return css`
    display: inline-block;
    margin-top: 1rem;
    cursor: pointer;
    color: #425c6a;
    padding: .5rem .9rem;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px #425c6a solid;
    font: 100 ${theme.fontSize.small} ${theme.fonts.accent};
    &:hover {
      color: white;
      background-color: #425c6a;
      border 0.2s ease-in-out 0.3s
    }
`
}
