import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CaseImage = props => {
  const data = useStaticQuery(graphql`
    query {
      huntsvilleImage: file(relativePath: { eq: "huntsville/001.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tenthImage: file(relativePath: { eq: "500/cube_200.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dagImage: file(relativePath: { eq: "2dag/cube_200.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      broadwayImage: file(relativePath: { eq: "1500/front_200.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schermerhornImage: file(relativePath: { eq: "63/cube_200.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eleventhImage: file(relativePath: { eq: "636/cube_200.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rockImage: file(relativePath: { eq: "rockville/1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      amsterdamImage: file(relativePath: { eq: "more/ams_200.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      berryImage: file(relativePath: { eq: "more/berry_200.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const huntsvilleImage = data.huntsvilleImage.childImageSharp.fluid
  const tenthImage = data.tenthImage.childImageSharp.fluid
  const dagImage = data.dagImage.childImageSharp.fluid
  const broadwayImage = data.broadwayImage.childImageSharp.fluid
  const schermerhornImage = data.schermerhornImage.childImageSharp.fluid
  const eleventhImage = data.eleventhImage.childImageSharp.fluid
  const rockImage = data.rockImage.childImageSharp.fluid
  const amsterdamImage = data.amsterdamImage.childImageSharp.fluid
  const berryImage = data.berryImage.childImageSharp.fluid
  const style = { maxHeight: "200px" }
  switch (props.for) {
    case "huntsville":
      return <Img style={style} fluid={huntsvilleImage} />
    case "500-tenth-avenue":
     return <Img style={style} fluid={tenthImage} />
    case "2-dag-hammerskjold-plaza":
      return <Img style={style} fluid={dagImage} />
    case "1500-broadway":
      return <Img style={style} fluid={broadwayImage} />
    case "63-schermerhorn-street":
      return <Img style={style} fluid={schermerhornImage} />
    case "636-eleventh-avenue":
      return <Img style={style} fluid={eleventhImage} />
    case "rockville-center":
      return <Img style={style} fluid={rockImage} />
    case "amsterdam":
      return <Img style={style} fluid={amsterdamImage} />
    case "berry":
      return <Img style={style} fluid={berryImage} />
    default:
      return <div />
  }
}
export default CaseImage
