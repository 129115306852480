import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Splash from "../../components/splash"
import LongBlocks from "../../components/longBlocks"

import { SELECTED } from "../../config"

const SelectedProjects = ({ images }) => (
  <Layout>
    <SEO title="Select Projects" />
    <Splash title="Select Projects">
      <Img style={{ minHeight: "150px" }} fluid={images[0]} />
    </Splash>
    <br />
    <LongBlocks blocks={renderBlocks()} />
  </Layout>
)

const renderBlocks = () => {
  return Object.keys(SELECTED).map((k, i) => {
    const case_ = SELECTED[k]
    const title = case_.title
    const text = case_.selected.text
    const location = case_.selected.location
    return {
      title,
      text,
      location,
      case: k,
    }
  })
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      splashImage: file(relativePath: { eq: "banners/s_p.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const splashImage = data.splashImage.childImageSharp.fluid

  const images = [splashImage, ,]
  return <SelectedProjects {...props} images={images} />
}
