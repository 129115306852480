import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { callToActionStyle } from "../styles"
import { CAPABILITIES } from "../config"
import CaseImage from "./caseImages"
import MediaImage from "./mediaImages"

const LongBlocks = ({ blocks }) => (
  <BlockContainer>{blocks.map(b => renderBlock(b))}</BlockContainer>
)
const BlockContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  margin-bottom: 5rem;
`

const Block = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  grid-gap: 0% 5%;
  grid-template-rows: auto;

  color: ${props => props.theme.colors.primary};
  margin: 2rem 5rem 2rem 0;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
    text-align: center;
    margin: 1rem 0;
    .gatsby-image-wrapper {
      margin: 0 auto;
    }
  }

  h4 {
    font: 400 40px ${props => props.theme.fonts.serif};
    line-height: 30px;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 30px;
      margin-top: 1rem;
    }
  }

  h5 {
    font: 400 32px ${props => props.theme.fonts.serif};
    margin: 1rem 0;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 24px;
      margin-top: 1rem;
    }
  }

  small {
    font-style: italic !important;
    font: 400 16px ${props => props.theme.fonts.serif};
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      display: none !important;
    }
  }

  p {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 1rem;
  }

  .location {
    margin-top: -1rem;
  }
`

const renderBlock = block => {
 console.log(block.case)
  return (
    <Block>
      {block.case && <CaseImage for={block.case} />}
      {block.media && <MediaImage for={block.media} />}
      <div>
        <h4>{block.title}</h4>
        {block.location && <p className="location">{block.location}</p>}
        <p>{block.text}</p>
        {block.capabilities && (
          <small style={{ display: "flex", listStyle: "none" }}>
            Relevant capabilities:
            <ul style={{ display: "flex", listStyle: "none" }}>
              {block.capabilities.map(c => (
                <li
                  style={{ textTransform: "capitalize", marginRight: "1rem" }}
                >
                  <a href={CAPABILITIES[c].href}>{CAPABILITIES[c].title}</a>
                </li>
              ))}
            </ul>
          </small>
        )}
        {block.href && (
          <Link css={theme => callToActionStyle(theme)} to={block.href}>
            {block.buttonText ? block.buttonText : "View Full Case Study"}
          </Link>
        )}
      </div>
    </Block>
  )
}

export default LongBlocks
